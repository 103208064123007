<script lang="ts" setup>
import {RefugeImage} from '~/types/RefugeImage';

const localePath = useLocalePath();

const props = defineProps({
  refugeImg: {type: RefugeImage, required: true},
  text: {type: String, required: true},
  link: {type: String, required: true}
});

</script>

<template>
  <nuxt-link :to="localePath(link)" class="details-side-image-container" aria-label="navigation" role="navigation">
    <div class="gradient-overlay"></div>

    <nuxt-img :src="refugeImg.link" :alt="refugeImg.alt" class="details-side-image"
              width="400px" height="400px" quality="80" format="jpg"/>
    <h2 class="details-image-text">»{{ text }}</h2>
  </nuxt-link>
</template>

<style lang="sass" scoped>
.gradient-overlay
  position: absolute
  width: 100%
  height: 100%
  background-color: transparent !important
  background-image: linear-gradient(to top, rgba(0, 0, 0, 0.64) 0, rgba(0, 0, 0, 0) 50%)
  transition: all 200ms ease-in-out
  z-index: 1

  &:hover
    opacity: 0

.details-image-text
  font-size: 1.2rem
  z-index: 2
  color: rgba(255, 255, 255, 0.8)
  text-shadow: 0 2px 10px rgba(0, 0, 0, 0.7)
  position: absolute
  bottom: 5%
  left: 5%
  transition: all 150ms ease

.details-side-image
  overflow: hidden
  cursor: pointer
  width: 100%
  height: 100%
  object-fit: cover
  transition: all 300ms ease

.details-side-image-container
  border-radius: 7px
  position: relative
  overflow: hidden
  aspect-ratio: 1 / 1.2
  transition: all 300ms ease
  cursor: pointer
  box-shadow: 0 8px 10px 0 rgba(0, 0, 0, 0.1)
  max-width: 400px
  width: 400px

  &:hover
    img
      scale: 1.02

@media screen and (max-width: 1280px)
  .details-side-image-container
    height: 400px
    width: auto
    border-radius: 0

@media (max-width: 480px)
  .details-side-image-container
    width: 100%

</style>
